/** @format */
import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import {
	LazyLoadImage,
	LazyLoadComponent,
} from 'react-lazy-load-image-component';
import data from '../../data.json';

function Gallery({ layout, category, db }) {
	const [showModal, setShowModal] = useState(false);
	const [imgInfo, setImgInfo] = useState(['', '', 1]);
	const [allowScroll, setAllowScroll] = useState('unset');

	const onShowModal = (e) => {
		e.preventDefault();
		setShowModal(true);
		setImgInfo([e.target.src, e.target.alt, e.target.id]);
		setAllowScroll('hidden');
	};

	const closeModal = () => {
		setShowModal(false);
		setAllowScroll('unset');
	};

	const fullSizeImg = parseInt(imgInfo[2]) - 1;

	useEffect(() => {
		//Prevent background scroll when opening modal
		window.document.body.style.overflowY = allowScroll;
	});

	return (
		<>
			<LazyLoadComponent>
				<Modal isOpen={showModal} onClose={closeModal} children>
					{db[fullSizeImg].full_size.map((i, index) => (
						<LazyLoadImage src={i} alt={i} key={index} />
					))}
				</Modal>
			</LazyLoadComponent>

			<div className={layout}>
				{db.map(
					(img) =>
						img.category === category && (
							<div className='portfolio-item' key={img.id}>
								<div className='preview-img'>
									<div className='link-btn'>
										<h5>{img.title}</h5>
										<button
											className='see-btn'
											id={img.id}
											onClick={(img) => onShowModal(img)}>
											See More
										</button>
									</div>
									<img
										className='thumbnail'
										src={img.thumbnail}
										alt={img.title}
										id={img.id}
										onClick={(img) => onShowModal(img)}
									/>
								</div>
								<p className='project-description'>{img.title}</p>
							</div>
						)
				)}
			</div>
		</>
	);
}

Gallery.defaultProps = {
	layout: 'grid',
	category: null,
	db: data.portfolio,
};

export default Gallery;
