import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

function Navbar() {
  const [clicked, setClicked] = useState(true);

  // Toggle hanburger button
  const handleClick = () => setClicked(!clicked);
  const handleClose = () => setClicked(true);

  return (
    <>
      <nav className='navbar'>
        <NavLink to='/' className='navbar-brand'>
          <img className='logo' src='img/Logo_light.png' alt='EW logo' />
        </NavLink>
        <ul className='navbar-nav'>
          <li className='nav-list'>
            <NavLink exact to='/' className='nav-link'>
              Home
            </NavLink>
          </li>
          <li className='nav-list'>
            <NavLink exact to='/web-dev' className='nav-link'>
              Web Dev
            </NavLink>
          </li>
          <li className='nav-list'>
            <NavLink exact to='/game-dev' className='nav-link'>
              Game Dev
            </NavLink>
          </li>
        </ul>
      </nav>

      {/* MOBILE NAVBAR */}
      <nav className='navbar-mobile'>
        <div className='default'>
          <NavLink to='/' className='navbar-brand'>
            <img className='logo' src='logo512.png' alt='EW logo' />
          </NavLink>
          <button
            className={clicked ? 'hamburger-btn' : 'hamburger-btn clicked'}
            onClick={handleClick}
          >
            <div className='bar' />
          </button>
        </div>

        <ul className={clicked ? 'navbar-nav' : 'navbar-nav mobile'}>
          <li className='nav-list'>
            <NavLink exact to='/' className='nav-link' onClick={handleClose}>
              Home
            </NavLink>
          </li>
          <li className='nav-list'>
            <NavLink
              exact
              to='/web-dev'
              className='nav-link'
              onClick={handleClose}
            >
              Web Dev
            </NavLink>
          </li>
          <li className='nav-list'>
            <NavLink
              exact
              to='/game-dev'
              className='nav-link'
              onClick={handleClose}
            >
              Game Dev
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
