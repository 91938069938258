import React, { useEffect, useState } from 'react';

function BackToTop() {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', showBackToTop);
    return () => {
      window.removeEventListener('scroll', showBackToTop);
    };
  });

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const showBackToTop = () => {
    if (!showScroll && window.pageYOffset > 900) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 900) {
      setShowScroll(false);
    }
  };
  return (
    <button
      className={showScroll ? 'back-to-top' : 'back-to-top disabled'}
      onClick={scrollTop}>
      <i className='fas fa-chevron-up' />
    </button>
  );
}

export default BackToTop;
