import React from 'react';

function SectionTitle({ title, firstCharacter }) {
  return (
    <h3 className='section-title' first-character={firstCharacter}>
      {title}
    </h3>
  );
}

SectionTitle.defaultProps = {
  title: 'Title',
  firstCharacter: 'T',
};

export default SectionTitle;
