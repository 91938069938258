import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Gamedev from './pages/Game-dev';
import Home from './pages/Home';
import Webdev from './pages/Web-dev';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Navbar />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/web-dev' component={Webdev} />
          <Route exact path='/game-dev' component={Gamedev} />
        </Switch>
        <Footer />
      </Router>
    </HelmetProvider>
  );
}

export default App;
