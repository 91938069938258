/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function Button({ type, name, href, target, event, isDownload, isAnchorLink }) {
  return (
    <>
      {isAnchorLink ? (
        <Link
          to={href}
          target={target}
          rel='noreferrer'
          className={type}
          onClick={event}
        >
          {name}
        </Link>
      ) : (
        <a
          href={href}
          target={target}
          rel='noreferrer'
          className={type}
          onClick={event}
          download={isDownload ? true : false}
        >
          {name}
        </a>
      )}
    </>
  );
}

Button.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  href: PropTypes.string,
  target: PropTypes.string,
  event: PropTypes.func,
  isDownload: PropTypes.bool,
  isAnchorLink: PropTypes.bool,
};

Button.defaultProps = {
  type: 'primary',
  name: 'Button',
  href: '/',
  target: '_self',
  event: null,
  isDownload: false,
  isAnchorLink: false,
};

export default Button;
