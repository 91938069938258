import React from 'react';

function Modal({ isOpen, onClose, children }) {
  return (
    <div className='modal' style={{ display: isOpen ? 'flex' : 'none' }}>
      <button onClick={onClose} className='close-btn'>
        <i className='fas fa-times' />
      </button>
      <div className='modal-content'>{children}</div>
    </div>
  );
}
export default Modal;
