import React from 'react';

function Header({ bgImg, headerType, children }) {
  return (
    <header
      className={headerType}
      style={{
        backgroundImage: `url(${bgImg})`,
      }}>
      {children}
    </header>
  );
}

Header.defaultProps = {
  bgImg: '/img/bg1.jpg',
  headerType: 'header',
};

export default Header;
